import styled from 'styled-components'
import media from '../../styles/media'

const LargeButtonStyle = `
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  padding: 19px 40px;

  color: #FFF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 48px;
  cursor: pointer;
`

const LargeButton = styled.button`
  ${LargeButtonStyle}
`

const LargeRedButton = styled(LargeButton)`
  background: var(--successDarkmode);
  ${media.sm`
      font-size: 20px;
      line-height: 32px;
      padding: 19px 32px;
    `}
`
const LargeGreenButton = styled(LargeButton)`
  background: var(--primary);
  ${media.sm`
      font-size: 20px;
      line-height: 32px;
      padding: 19px 32px;
    `}
`

const LargeButtonDiv = styled.div`
  ${LargeButtonStyle}
  ${media.sm`
      font-size: 20px;
      line-height: 32px;
      padding: 19px 32px;
    `}
`

const LargeGreenButtonWithIcon = styled(LargeButtonDiv)`
  background: var(--primary);
  padding: 19px 32px;

  display: flex;
  align-items: center;
  font-size: 17px;

  img {
    margin-right: 16px;
  }
  ${media.sm`
      font-size: 20px;
      line-height: 32px;
      padding: 19px 32px;
    `}
`

export { LargeRedButton, LargeGreenButton, LargeGreenButtonWithIcon }
