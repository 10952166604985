import Header from '../components/Header'
import FooterComponent from '../components/Footer'

export default function Layout({ withFooter, ...props }) {
  return (
    <>
      <Header />
      <div className="mh">{props.children}</div>
      {withFooter && <FooterComponent />}
    </>
  )
}
